export default [
  {
    path: "/main",
    meta: {
      name: "Main View",
      isAuthenticated: true,
      roles: ['ais_user']
    },
    component: () => import(`@/views/main/Main.vue`)
  },
  {
    path: "/",
    meta: {
      name: "Main View",
      isAuthenticated: true,
      roles: ['ais_user']
    },
    component: () => import(`@/views/main/Main.vue`)
  },
  {
    path: "/old/architekturerfassung",
    meta: {
      name: "Architekturerfassung View",
      isAuthenticated: true,
      roles: ['ais_admin']
    },
    component: () => import(`@/views/old/architektur/ArchitekturErfassung.vue`)
  },
  {
    path: "/old/architekturNavigation",
    meta: {
      name: "ArchitekturNavigation View",
      isAuthenticated: true,
      roles: ['ais_admin']
    },
    component: () => import(`@/views/old/architektur/ArchitekturNavigation.vue`),
    children: [
      {
        path: "/old/architekturUebersicht",
        meta: {
          name: "Uebersicht View",
          isAuthenticated: true,
          roles: ['ais_admin']
        },
        component: () => import(`@/views/old/architektur/Uebersicht.vue`)
      },
      {
        path: "/old/fachlicheAnforderungen",
        meta: {
          name: "Fachliche Anforderungen View",
          isAuthenticated: true,
          roles: ['ais_admin']
        },
        component: () =>
          import(
            `@/views/old/architektur/fachlicherKontext/AnforderungenFachlich.vue`
          )
      },
      {
        path: "/old/akteure",
        meta: {
          name: "Akteure View",
          isAuthenticated: true,
          roles: ['ais_admin']
        },
        component: () => import(`@/views/old/architektur/akteure/Akteure.vue`)
      },
      {
        path: "/old/schnittstellen",
        meta: {
          name: "Schnittstellen View",
          isAuthenticated: true,
          roles: ['ais_admin']
        },
        component: () =>
          import(`@/views/old/architektur/schnittstellen/Schnittstellen.vue`)
      },
      {
        path: "/old/technologieliste",
        meta: {
          name: "Technologieliste View",
          isAuthenticated: true,
          roles: ['ais_admin']
        },
        component: () =>
          import(`@/views/old/architektur/technologieliste/Technologieliste.vue`)
      },
      {
        path: "/old/bibliothekliste",
        meta: {
          name: "Bibliothekliste View",
          isAuthenticated: true,
          roles: ['ais_admin']
        },
        component: () =>
          import(`@/views/old/architektur/bibliothekliste/Bibliothekliste.vue`)
      },
      {
        path: "/old/finanzkennzahlen",
        meta: {
          name: "Finanzkennzahlen View",
          isAuthenticated: true,
          roles: ['ais_admin']
        },
        component: () =>
          import(`@/views/old/architektur/finanzkennzahlen/Ola.vue`)
      }
    ]
  },
  {
    path: "/old/berichteuebersicht",
    meta: {
      name: "Berichteübersicht View",
      isAuthenticated: true,
      roles: ['ais_admin']
    },
    component: () => import(`@/views/old/berichte/BerichteUebersicht.vue`)
  },
  {
    path: "/old/main",
    meta: {
      name: "Main View Old",
      isAuthenticated: true,
      roles: ['ais_admin']
    },
    component: () => import(`@/views/old/main/MainOld.vue`)
  },
  {
    path: "/entity/main",
    meta: {
      name: "Main View Entity",
      isAuthenticated: true,
      roles: ['ais_admin']
    },
    component: () => import(`@/views/entity/Main.vue`)
  },
  
{
path: "/entity/projekt",
meta: {
  name: "Projekt View Entity",
  isAuthenticated: true,
  roles: ['ais_admin']
},
component: () => import(`@/views/entity/Projekt.vue`)
},

{
path: "/entity/lieferant",
meta: {
  name: "Lieferant View Entity",
  isAuthenticated: true,
  roles: ['ais_admin']
},
component: () => import(`@/views/entity/Lieferant.vue`)
},

{
path: "/entity/nameOfSuperClass",
meta: {
  name: "NameOfSuperClass View Entity",
  isAuthenticated: true,
  roles: ['ais_admin']
},
component: () => import(`@/views/entity/NameOfSuperClass.vue`)
},

{
path: "/entity/dateTest",
meta: {
  name: "DateTest View Entity",
  isAuthenticated: true,
  roles: ['ais_admin']
},
component: () => import(`@/views/entity/DateTest.vue`)
},

//end here
  {
    path: "/denied",
    name: "denied",
    meta: {
      name: "Denied",
      isAuthenticated: false,
    },
    component: () => import(`@/views/Forbidden.vue`)
  },
  {
    path: "/*",
    meta: { isAuthenticated: false },
    component: () => import("@/views/NotFound.vue")
  },
  {
    path: "/:catchAll(.*)",
    meta: { isAuthenticated: false },
    component: () => import("@/views/NotFound.vue")
  }
];
