class LocaleServcie {
    getAllTransLations() {
        let data = {
            de: {
                "entity": {
                    "heading": "Enititäten bearbeiten",
                    "short": "Hier können die Entitäten bearbeitet werden damit wir das auch mal testen können.",
                    "long": "Diesen Text müsen wir noch bearbeiten. <br/><b>Test</b>",
                    "label": "Entitäten",
                    "list": {
                        "projekt": "Projekt",
                        "lieferant": "Lieferant",
                        "nameOfSuperClass": "Name of Superclass",
                        "dateTest" : "Date-Test"
                    },
                    "references": {
                        "head": "Beziehungen bearbeiten für {vertexName}",
                        "edgeType": {
                            "short": "Edge-Type",
                            "middel": "Edge-Type",
                            "long": "EdgeType",
                            "rule": "Edge-Type erforderlich"
                        },
                        "destinationVertexType": {
                            "short": "Destination-Vertex-Type",
                            "middel": "Destination-Vertex-Type",
                            "long": "Destination-Vertex-Type",
                            "rule": "Destination-Vertex-Type erforderlich"
                        },
                        "destinationVertexName": {
                            "short": "Destination-Vertex-Name",
                            "middel": "Destination-Vertex-Name",
                            "long": "Destination-Vertex-Name",
                            "rule": "Destination-Vertex-Name erforderlich"
                        },
                        "originVertexType": {
                            "short": "Orgin-Vertex-Type",
                            "middel": "Origin-Vertex-Type",
                            "long": "Origin-Vertex-Type"
                        },
                        "originVertexName": {
                            "short": "Origin-Vertex",
                            "middel": "Origin-Vertex",
                            "long": "Origin-Vertex"
                        },
                        "question": {
                            "add": "Beziehung hinzufügen",
                            "change": "Beziehung ändern",
                            "form": "Hier können Sie Beziehungen hinzufügen"
                        }

                    },
                    "projekt": {
                        "head": "Projekte",
                        "formDescription": "Bitte hier die entsprechenden Daten zu einem Eintrag erfassen.</br>Berücksichtigen Sie dabei bitte das Recht das für die Sichtbarkeit eines Eintrags benötigt wird.",
                        "a1": {
                            "short": "A1",
                            "middel": "A1",
                            "long": "A1"
                        },
                        "a2": {
                            "short": "A2",
                            "middel": "A2",
                            "long": "A2",
                            "rule": "A2 erforderlich"
                        },
                        "a4": {
                            "short": "A4",
                            "middel": "A4",
                            "long": "A4",
                            "rule": "A4 erforderlich"
                        },
                        "a6": {
                            "short": "A6",
                            "middel": "A6",
                            "long": "A6",
                            "hint": "Bitte ein Double-Wert eingeben",
                            "rule": "A6 erforderlich"
                        },
                        "bez": {
                            "short": "Bez",
                            "middel": "Bezeichnung",
                            "long": "Bezeichnung",
                            "rule": "Bezeichnung erforderlich"
                        },
                        "ident": {
                            "short": "ident",
                            "middel": "ident",
                            "long": "ident",
                            "hint": "Nach Namenskonzept",
                            "rule": "Ident erforderlich"
                        },
                        "vertexName": {
                            "short": "Vertex-Name",
                            "middel": "Vertex-Name",
                            "long": "Vertex-Name",
                        },
                    },
                    "lieferant": {
                        "head": "Lieferant",
                        "formDescription": "Bitte hier die entsprechenden Daten zu einem Eintrag erfassen.</br>Berücksichtigen Sie dabei bitte das Recht das für die Sichtbarkeit eines Eintrags benötigt wird.",
                        "a2": {
                            "short": "A2",
                            "middel": "A2",
                            "long": "A2",
                            "rule": "A2 erforderlich"
                        },
                        "superClassAttribute": {
                            "short": "Superclass Attr",
                            "middel": "Superclass Attribute",
                            "long": "Superclas Attribute",
                            "rule": "Superclass Attribute erforderlich"
                        },
                        "bez": {
                            "short": "Bez",
                            "middel": "Bezeichnung",
                            "long": "Bezeichnung",
                            "rule": "Bezeichnung erforderlich"
                        },
                        "ident": {
                            "short": "ident",
                            "middel": "ident",
                            "long": "ident",
                            "hint": "Nach Namenskonzept",
                            "rule": "Ident erforderlich"
                        },
                        "vertexName": {
                            "short": "Vertex-Name",
                            "middel": "Vertex-Name",
                            "long": "Vertex-Name",
                        },
                    },
                    "nameOfSuperClass": {
                        "head": "Name of SuperClass",
                        "formDescription": "Bitte hier die entsprechenden Daten zu einem Eintrag erfassen.</br>Berücksichtigen Sie dabei bitte das Recht das für die Sichtbarkeit eines Eintrags benötigt wird.",
                        "superClassAttribute": {
                            "short": "Superclass Attr",
                            "middel": "Superclass Attribute",
                            "long": "Superclas Attribute",
                            "rule": "Superclass Attribute erforderlich"
                        },
                        "bez": {
                            "short": "Bez",
                            "middel": "Bezeichnung",
                            "long": "Bezeichnung",
                            "rule": "Bezeichnung erforderlich"
                        },
                        "ident": {
                            "short": "ident",
                            "middel": "ident",
                            "long": "ident",
                            "hint": "Nach Namenskonzept",
                            "rule": "Ident erforderlich"
                        },
                        "vertexName": {
                            "short": "Vertex-Name",
                            "middel": "Vertex-Name",
                            "long": "Vertex-Name",
                        },
                    },
                    "dateTest": {
                        "head": "Date-Test",
                        "formDescription": "Bitte hier die entsprechenden Daten zu einem Eintrag erfassen.</br>Berücksichtigen Sie dabei bitte das Recht das für die Sichtbarkeit eines Eintrags benötigt wird.",
                        "datum": {
                            "short": "Datum",
                            "middel": "Datum",
                            "long": "Datum",
                            "rule": "Datum erforderlich"
                        },
                        "ad": {
                            "short": "AD",
                            "middel": "AD",
                            "long": "AD",
                            "rule": "AD erforderlich"
                        },
                        "superClassAttribute": {
                            "short": "Superclass Attr",
                            "middel": "Superclass Attribute",
                            "long": "Superclas Attribute",
                            "rule": "Superclass Attribute erforderlich"
                        },
                        "bez": {
                            "short": "Bez",
                            "middel": "Bezeichnung",
                            "long": "Bezeichnung",
                            "rule": "Bezeichnung erforderlich"
                        },
                        "ident": {
                            "short": "ident",
                            "middel": "ident",
                            "long": "ident",
                            "hint": "Nach Namenskonzept",
                            "rule": "Ident erforderlich"
                        },
                        "vertexName": {
                            "short": "Vertex-Name",
                            "middel": "Vertex-Name",
                            "long": "Vertex-Name",
                        },
                    }
                },
                "common": {
                    "button": {
                        "start": "Starten",
                        "ok": "OK",
                        "cancel": "Abbrechen",
                        "save": "Speichern",
                        "assume": "Übernehemen",
                        "add": "Hinzufügen",
                        "reload": "neu laden",
                        "close": "Schließen"
                    },
                    "field": {
                        "vertex": {
                            "short": "Vertex",
                            "middel": "Vertex",
                            "long": "Vertex"
                        },
                        "references": {
                            "short": "Beziehungen",
                            "middel": "Beziehungen",
                            "long": "Beziehungen"
                        },
                        "actions": {
                            "short": "Aktionen",
                            "middel": "Aktionen",
                            "long": "Aktionen"
                        },
                        "lastUser": {
                            "short": "Benutzer",
                            "middel": "geändert durch",
                            "long": "zuletzt geändert durch"
                        },
                        "lastTimestamp": {
                            "short": "Zeitstempel",
                            "middel": "geändert am",
                            "long": "zuletzt geändert am"
                        },
                    },
                    "question": {
                        "delete": "Möchten Sie den Eintrag entfernen?",
                        "add": "Eintrag hinzufügen",
                        "change": "Eintrag ändern"
                    },
                    "search": "suchen",
                    "footer": {
                        "appname": "AIS - Architektur Informationssystem",
                        "owner": "C3.2 Architektur, Informationssicherheit und Datenschutz",
                        "user": "Angemeldet als {user}"
                    },
                    "defaultHeader": "Core | AIS",
                    "defaultHeaderShort": "Core",
                    "defaultHeaderTemplate": "{s} | AIS",
                    "references": {
                        "directionIn": "in:",
                        "directionOut": "out:"
                    },
                    "messages": {
                        "load": "Einträge gelesen",
                        "noData": "Keine Daten vorhanden",
                        "save": "Änderungen wurden gespeichert"
                    }
                },
                "nav": {
                    "main": "Main",
                    "entity": "Entitäten",
                    "start": {
                        "old": {
                            "name": "Alte Version der Anwendung",
                            "desc": "Die alte Version als Inspirationsquelle"
                        },
                        "entity": {
                            "name": "Entitäten bearbeiten",
                            "desc": "Möglichkeiten die Entitäten direkt zu bearbeiten"
                        }
                    }
                },
                "help": {
                    "additional": "Weitere Hilfe",
                    "entity": {
                        "head": "Eintitäten bearbeiten",
                        "desc": "Die Maske <strong>Enitäten bearbieten</strong> bietet die Möglichkeit einzelne Entitäten zu bearbeiten.<br/><br/>Wählen Sie die gewünschte Entität aus und klicken Sie auf Starten"
                    },
                    "projekte": {
                        "head": "Projekte bearbeiten",
                        "desc": " Die Maske <strong>Projekte bearbieten</strong> bietet die Möglichkeit einzelne Projekte zu bearbeiten.<br/><br/>Wählen Sie das gewünschte Projekt aus und klicken Sie auf eine der gewünschten Aktionen"
                    }
                },
                "errorPages": {
                    "notFound": {
                        "head": "Seite nicht gefunden",
                        "p1": "Die von Ihnen angeforderte Seite existiert nicht mehr, hat nie existiert, hat nie in dieser Realität existiert oder wurde in ein Paralleluniversum migriert ;)",
                        "p2": "Bitte gehen Sie zurück auf die Startseite, oder kontaktieren den Support.",
                        "p3": "",
                    },
                    "forbidden": {
                        "head": "Forbidden",
                        "p1": "Für die angeforderte Aktion besitzen Sie keine ausreichenden Rechte.",
                        "p2": "Sollte diese Seite direkt nach dem ersten Aufruf der Anwendung sichtbar sein. Aktualisieren Sie bitte mit F5 die Seite und kehren zur Startseite zurück",
                        "p3": "Bitte gehen Sie zurück auf die Startseite, oder kontaktieren den Support.",
                    }
                }
            },
            en:
            {
                "message": "hello i18n !!",
                "hello": "Erster Test mit Language-Service(en)"
            }
        }
        return data;
    }
}

export default new LocaleServcie();
